const _env = "uat";

const _variables = {};

export const env = () => {
  _variables.ENV = _env;
  if (_env === "uat") {
    _variables.BASEURL = process.env.REACT_APP_BASEURL_UAT;
    _variables.API_KEY = process.env.REACT_APP_DEV_API_KEY;
    _variables.COGNITO_APP_CLIENT_ID =
      process.env.REACT_APP_COGNITO_APP_CLIENT_ID_UAT;
  } else if (_env === "prod") {
    _variables.BASEURL = process.env.REACT_APP_BASEURL_PROD;
    _variables.API_KEY = process.env.REACT_APP_PROD_API_KEY;
    _variables.COGNITO_APP_CLIENT_ID =
      process.env.REACT_APP_COGNITO_APP_CLIENT_ID_PROD;
  }
  _variables.TTL_MINS = process.env.REACT_APP_TTL_MINS;
  _variables.DIRECT_AFTER_LOGIN = process.env.REACT_APP_DIRECT_AFTER_LOGIN;
  _variables.GCLIENT_ID = process.env.REACT_APP_GCLIENT_ID;
  _variables.GCLIENT_SCOPE = process.env.REACT_APP_GCLIENT_SCOPE;
  _variables.RECORD_SET_SIZE = process.env.REACT_APP_RECORD_SET_SIZE;
  _variables.REDIRECT_TIMEOUT = process.env.REACT_APP_REDIRECT_TIMEOUT;
  _variables.LAST_SEALER_RATE_REVISION =
    process.env.REACT_APP_LAST_SEALER_RATE_REVISION;
  _variables.VERSION = process.env.REACT_APP_VERSION;
  _variables.COGNITO_ACCESS_KEY_ID =
    process.env.REACT_APP_COGNITO_ACCESS_KEY_ID;
  _variables.COGNITO_ACCESS_KEY = process.env.REACT_APP_COGNITO_ACCESS_KEY;
  _variables.REGION = process.env.REACT_APP_REGION;
  _variables.S3_SIGNATURE_VERSION = process.env.REACT_APP_S3_SIGNATURE_VERSION;
  return _variables;
};
